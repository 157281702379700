import logo from './img/logo.png';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'SimuRealm';
  }, []);
  return (
    <>
      <img src={logo} /><br />
      2<br />
      &copy; SimuRealm
    </>
  );
}

export default App;
